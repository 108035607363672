import { MatSnackBar } from "@angular/material/snack-bar";
import { CartService } from "./../../../services/cart.service";
import { Router, ActivatedRoute } from "@angular/router";
import { FileService } from "./../../../services/file.service";
import { HealthScreeningOverview, HealthScreeningService, HealthscreeningTopic } from "./../../../services/health-screening.service";
import { Component, OnInit } from "@angular/core";
import { FormHelper } from "../../../helper/form.helper";
import { UntypedFormGroup, UntypedFormBuilder } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { ValueLabel } from "src/app/components/value-label";
import { take } from "rxjs/operators";

@Component({
  selector: "customer-health-screening-overview-page",
  templateUrl: "./customer-health-screening-overview-page.component.html",
  styleUrls: ["./customer-health-screening-overview-page.component.scss"],
})
export class CustomerHealthScreeningOverviewPageComponent implements OnInit {
  healthscreenings: any[] = [];
  filteredHealthscreenings: any[] = [];
  userRole: string;

  private _searchTerm: string;
  get searchTerm(): string {
    return this._searchTerm;
  }
  set searchTerm(value: string) {
    this._searchTerm = value;
    this.filteredHealthscreenings = this.applyFilter(value);
  }

  type: string;
  topicOptions: ValueLabel[] = [];
  filterDataForm: UntypedFormGroup = this.formBuilder.group({
    topics: [this.topicOptions, []],
  });

  constructor(
    private healthscreeningService: HealthScreeningService,
    private fileService: FileService,
    private router: Router,
    private formHelper: FormHelper,
    private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private cartService: CartService,
    private _snackBar: MatSnackBar,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.userRole = localStorage.getItem("role");
    this.loadHealthScreenings();
    this.getTopics();
  }

  ngAfterViewInit() {
    this.filterDataForm.valueChanges.subscribe(() => {
      this.applyAdvancedFilter();
    });
  }

  loadHealthScreenings() {
    this.healthscreeningService.findAllOverview().subscribe((res) => {
      res.body.sort(this.formHelper.sortByModuleName);
      this.route.params.subscribe((values) => {
        this.healthscreenings = [];
        this.filteredHealthscreenings = [];
        this.type = values["type"];
        for (const healthscreening of res.body) {
          if (this.type === "INDOOR_OUTDOOR") {
            if (healthscreening.moduleType === "HEALTH_MODULE" && healthscreening.onsite) {
              this.processHealthScreeningsByTopic(healthscreening);
            }
          } else if (this.type === "ONLINE") {
            if (healthscreening.moduleType === "HEALTH_MODULE" && healthscreening.online) {
              this.processHealthScreeningsByTopic(healthscreening);
            }
          } else if (this.type === "WEBINAR") {
            if (healthscreening.moduleType === "WEBINAR") {
              this.processHealthScreeningsByTopic(healthscreening);
            }
          } else if (this.type === "ACTIVE_BREAK") {
            if (healthscreening.moduleType === "ACTIVE_BREAK") {
              this.processHealthScreeningsByTopic(healthscreening);
            }
          }
        }
        this.filteredHealthscreenings = this.healthscreenings;

        const uniqueIds = [...new Set(this.healthscreenings.map((hs) => hs.id))];
        for (const id of uniqueIds) {
          this.fileService.getFileMetaByHealthscreening(id, "HEALTHSCREENING_PICTURE").subscribe((filemetas) => {
            const imageUrls = this.fileService.createRessourceUrls(filemetas);
            const indexes = this.findHealthScreeningIndex(this.healthscreenings, id);
            indexes.forEach((index) => (this.healthscreenings[index].imageUrls = imageUrls));
          });
        }

        setTimeout(() => {
          window.scrollTo({
            top: this.formHelper.getScrollPosition("CustomerHealthScreeningOverviewPageComponent"),
            behavior: "smooth",
          });
        }, 100);
      });
    });
  }

  private processHealthScreeningsByTopic(healthScreening: HealthScreeningOverview) {
    // const topics: string[] = healthScreening.topic.split(",");
    // topics.forEach((topic) => {
    //   const hs: HealthScreeningOverview = {
    //     ...healthScreening,
    //     topic: topic.trim(),
    //   };
    //   this.healthscreenings.push(hs);
    // });
    this.healthscreenings.push(healthScreening);
  }

  applyFilter(searchString: string) {
    return this.healthscreenings.filter((item) => (item.moduleName && item.moduleName.toLowerCase().indexOf(searchString.toLowerCase()) !== -1) || (item.shortDescription && item.shortDescription.toLowerCase().indexOf(searchString.toLowerCase()) !== -1));
  }

  getTopics() {
    this.healthscreeningService
      .getTopics()
      .pipe(take(1))
      .subscribe((res) => {
        const topics = res.body.map((topic) => ({ value: topic, label: topic }));
        this.topicOptions.push(...topics);
        // Set all topics as selected by default in the form
        const allTopicValues = this.topicOptions.map((topic) => topic.value);
        this.filterDataForm.get("topics").setValue(allTopicValues);
      });
  }

  applyAdvancedFilter() {
    this.filteredHealthscreenings = [];
    for (let topic of this.filterDataForm.get("topics").value) {
      let hsToPush = this.healthscreenings?.filter((hs) => hs.topic.includes(topic));
      for (let hs of hsToPush) {
        const index = this.filteredHealthscreenings.map((ehs) => ehs.id).indexOf(hs.id);
        if (index === -1) {
          this.filteredHealthscreenings.push(hs);
        }
      }
    }
  }

  findHealthScreeningIndex(hsArray: any[], id: any): number[] {
    const indexes: number[] = [];
    for (let i = 0; i < hsArray.length; i++) {
      if (hsArray[i].id === id) {
        indexes.push(i);
      }
    }
    return indexes;
  }

  // findHealthScreeningIndex(hsArray: any[], id: any) {
  //   for (let i = 0; i < hsArray.length; i++) {
  //     if (hsArray[i].id === id) {
  //       return i;
  //     }
  //   }
  //   return -1;
  // }

  goToInfoPage(event: { id: number; topic: string }) {
    this.formHelper.saveScrollPosition("CustomerHealthScreeningOverviewPageComponent", window.pageYOffset);
    this.router.navigate(["/info-health-screening-page/" + event.id + "/" + this.type]);
  }

  topicHasModules(topic: string) {
    for (const healthscreening of this.healthscreenings) {
      if (healthscreening.topic === topic) {
        return true;
      }
    }
    return false;
  }

  addModuleToCart(hs: any) {
    // add module to cart
    const topics: string[] = hs.topic.split(",");
    if (topics.length > 0) {
      this.cartService.addToCart(hs, this.type === "ONLINE", topics[0]);
      this.openSnackBar();
    }
  }

  openSnackBar() {
    const message = this.translateService.instant("moduleAddedToCart");
    const action = "";
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }
}
